exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-bc-js": () => import("./../../../src/pages/about-bc.js" /* webpackChunkName: "component---src-pages-about-bc-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-anime-awards-and-testimonials-js": () => import("./../../../src/pages/anime-awards-and-testimonials.js" /* webpackChunkName: "component---src-pages-anime-awards-and-testimonials-js" */),
  "component---src-pages-anime-home-js": () => import("./../../../src/pages/anime-home.js" /* webpackChunkName: "component---src-pages-anime-home-js" */),
  "component---src-pages-anime-work-js": () => import("./../../../src/pages/anime-work.js" /* webpackChunkName: "component---src-pages-anime-work-js" */),
  "component---src-pages-awards-google-sans-js": () => import("./../../../src/pages/awards-google-sans.js" /* webpackChunkName: "component---src-pages-awards-google-sans-js" */),
  "component---src-pages-awards-gotham-js": () => import("./../../../src/pages/awards-gotham.js" /* webpackChunkName: "component---src-pages-awards-gotham-js" */),
  "component---src-pages-awards-graphik-js": () => import("./../../../src/pages/awards-graphik.js" /* webpackChunkName: "component---src-pages-awards-graphik-js" */),
  "component---src-pages-awards-js": () => import("./../../../src/pages/awards.js" /* webpackChunkName: "component---src-pages-awards-js" */),
  "component---src-pages-awards-poppins-js": () => import("./../../../src/pages/awards-poppins.js" /* webpackChunkName: "component---src-pages-awards-poppins-js" */),
  "component---src-pages-awards-recognition-js": () => import("./../../../src/pages/awards-recognition.js" /* webpackChunkName: "component---src-pages-awards-recognition-js" */),
  "component---src-pages-awards-vers-polysans-js": () => import("./../../../src/pages/awards-vers-polysans.js" /* webpackChunkName: "component---src-pages-awards-vers-polysans-js" */),
  "component---src-pages-blog-detail-google-sans-js": () => import("./../../../src/pages/blog-detail-google-sans.js" /* webpackChunkName: "component---src-pages-blog-detail-google-sans-js" */),
  "component---src-pages-blog-detail-graphik-js": () => import("./../../../src/pages/blog-detail-graphik.js" /* webpackChunkName: "component---src-pages-blog-detail-graphik-js" */),
  "component---src-pages-blog-detail-js": () => import("./../../../src/pages/blog-detail.js" /* webpackChunkName: "component---src-pages-blog-detail-js" */),
  "component---src-pages-blog-detail-polysans-js": () => import("./../../../src/pages/blog-detail-polysans.js" /* webpackChunkName: "component---src-pages-blog-detail-polysans-js" */),
  "component---src-pages-blog-detail-ptserif-js": () => import("./../../../src/pages/blog-detail-ptserif.js" /* webpackChunkName: "component---src-pages-blog-detail-ptserif-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-strapi-blog-slug-js": () => import("./../../../src/pages/blog/{strapiBlog.slug}.js" /* webpackChunkName: "component---src-pages-blog-strapi-blog-slug-js" */),
  "component---src-pages-casestudies-e-commerce-simon-west-ecommerce-website-js": () => import("./../../../src/pages/casestudies/e-commerce/simon-west-ecommerce-website.js" /* webpackChunkName: "component---src-pages-casestudies-e-commerce-simon-west-ecommerce-website-js" */),
  "component---src-pages-casestudies-e-commerce-strapi-project-slug-js": () => import("./../../../src/pages/casestudies/e-commerce/{strapiProject.slug}.js" /* webpackChunkName: "component---src-pages-casestudies-e-commerce-strapi-project-slug-js" */),
  "component---src-pages-casestudies-gg-180225-strapi-project-slug-js": () => import("./../../../src/pages/casestudies/gg-180225/{strapiProject.slug}.js" /* webpackChunkName: "component---src-pages-casestudies-gg-180225-strapi-project-slug-js" */),
  "component---src-pages-casestudies-mobile-apps-afl-players-moji-mobile-application-js": () => import("./../../../src/pages/casestudies/mobile-apps/afl-players-moji-mobile-application.js" /* webpackChunkName: "component---src-pages-casestudies-mobile-apps-afl-players-moji-mobile-application-js" */),
  "component---src-pages-casestudies-mobile-apps-strapi-project-slug-js": () => import("./../../../src/pages/casestudies/mobile-apps/{strapiProject.slug}.js" /* webpackChunkName: "component---src-pages-casestudies-mobile-apps-strapi-project-slug-js" */),
  "component---src-pages-casestudies-mobile-apps-warnie-moji-mobile-application-js": () => import("./../../../src/pages/casestudies/mobile-apps/warnie-moji-mobile-application.js" /* webpackChunkName: "component---src-pages-casestudies-mobile-apps-warnie-moji-mobile-application-js" */),
  "component---src-pages-casestudies-strapi-project-category-slug-js": () => import("./../../../src/pages/casestudies/{strapiProjectCategory.slug}.js" /* webpackChunkName: "component---src-pages-casestudies-strapi-project-category-slug-js" */),
  "component---src-pages-casestudies-web-apps-albya-web-application-js": () => import("./../../../src/pages/casestudies/web-apps/albya-web-application.js" /* webpackChunkName: "component---src-pages-casestudies-web-apps-albya-web-application-js" */),
  "component---src-pages-casestudies-web-apps-jabri-web-application-js": () => import("./../../../src/pages/casestudies/web-apps/jabri-web-application.js" /* webpackChunkName: "component---src-pages-casestudies-web-apps-jabri-web-application-js" */),
  "component---src-pages-casestudies-web-apps-louis-vuitton-web-application-js": () => import("./../../../src/pages/casestudies/web-apps/louis-vuitton-web-application.js" /* webpackChunkName: "component---src-pages-casestudies-web-apps-louis-vuitton-web-application-js" */),
  "component---src-pages-casestudies-web-apps-rww-web-application-js": () => import("./../../../src/pages/casestudies/web-apps/rww-web-application.js" /* webpackChunkName: "component---src-pages-casestudies-web-apps-rww-web-application-js" */),
  "component---src-pages-casestudies-web-apps-strapi-project-slug-js": () => import("./../../../src/pages/casestudies/web-apps/{strapiProject.slug}.js" /* webpackChunkName: "component---src-pages-casestudies-web-apps-strapi-project-slug-js" */),
  "component---src-pages-casestudies-web-apps-viktrs-web-application-js": () => import("./../../../src/pages/casestudies/web-apps/viktrs-web-application.js" /* webpackChunkName: "component---src-pages-casestudies-web-apps-viktrs-web-application-js" */),
  "component---src-pages-casestudies-websites-komodo-websites-js": () => import("./../../../src/pages/casestudies/websites/komodo-websites.js" /* webpackChunkName: "component---src-pages-casestudies-websites-komodo-websites-js" */),
  "component---src-pages-casestudies-websites-runway-websites-js": () => import("./../../../src/pages/casestudies/websites/runway-websites.js" /* webpackChunkName: "component---src-pages-casestudies-websites-runway-websites-js" */),
  "component---src-pages-casestudies-websites-strapi-project-slug-js": () => import("./../../../src/pages/casestudies/websites/{strapiProject.slug}.js" /* webpackChunkName: "component---src-pages-casestudies-websites-strapi-project-slug-js" */),
  "component---src-pages-casestudy-essestone-js": () => import("./../../../src/pages/casestudy-essestone.js" /* webpackChunkName: "component---src-pages-casestudy-essestone-js" */),
  "component---src-pages-casestudy-komodo-image-tag-js": () => import("./../../../src/pages/casestudy-komodo-image-tag.js" /* webpackChunkName: "component---src-pages-casestudy-komodo-image-tag-js" */),
  "component---src-pages-casestudy-komodo-js": () => import("./../../../src/pages/casestudy-komodo.js" /* webpackChunkName: "component---src-pages-casestudy-komodo-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-fff-casestudy-js": () => import("./../../../src/pages/fff-casestudy.js" /* webpackChunkName: "component---src-pages-fff-casestudy-js" */),
  "component---src-pages-four-o-four-js": () => import("./../../../src/pages/four-o-four.js" /* webpackChunkName: "component---src-pages-four-o-four-js" */),
  "component---src-pages-home-optimize-js": () => import("./../../../src/pages/home-optimize.js" /* webpackChunkName: "component---src-pages-home-optimize-js" */),
  "component---src-pages-index-home-2-js": () => import("./../../../src/pages/index-home-2.js" /* webpackChunkName: "component---src-pages-index-home-2-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-new-home-2-js": () => import("./../../../src/pages/new-home-2.js" /* webpackChunkName: "component---src-pages-new-home-2-js" */),
  "component---src-pages-new-home-js": () => import("./../../../src/pages/new-home.js" /* webpackChunkName: "component---src-pages-new-home-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-artificial-intelligence-js": () => import("./../../../src/pages/services/artificial-intelligence.js" /* webpackChunkName: "component---src-pages-services-artificial-intelligence-js" */),
  "component---src-pages-services-mobile-app-development-js": () => import("./../../../src/pages/services/mobile-app-development.js" /* webpackChunkName: "component---src-pages-services-mobile-app-development-js" */),
  "component---src-pages-services-mobileapps-old-js": () => import("./../../../src/pages/services/mobileapps-old.js" /* webpackChunkName: "component---src-pages-services-mobileapps-old-js" */),
  "component---src-pages-services-offshore-software-developers-js": () => import("./../../../src/pages/services/offshore-software-developers.js" /* webpackChunkName: "component---src-pages-services-offshore-software-developers-js" */),
  "component---src-pages-services-web-application-development-js": () => import("./../../../src/pages/services/web-application-development.js" /* webpackChunkName: "component---src-pages-services-web-application-development-js" */),
  "component---src-pages-services-website-design-js": () => import("./../../../src/pages/services/website-design.js" /* webpackChunkName: "component---src-pages-services-website-design-js" */),
  "component---src-pages-services-website-design-old-js": () => import("./../../../src/pages/services/website-design-old.js" /* webpackChunkName: "component---src-pages-services-website-design-old-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-pages-zanyar-outhman-js": () => import("./../../../src/pages/zanyar-outhman.js" /* webpackChunkName: "component---src-pages-zanyar-outhman-js" */)
}

